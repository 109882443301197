.t-information-disclosure {
    margin-top: 64px;
    &__item {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: background-color .3s;
            z-index: -1; }
        &:not(:last-child) {
            box-shadow: inset 0 -1px 0 0 rgba(46, 53, 62, 0.1); }
        &._open {
            box-shadow: none;
            &:after {
                background-color: rgba($text-head, .08); } } }
    &__head {
        padding: 40px 32px;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        p {
            margin-right: 32px;
            font-size: 24px;
            line-height: 1.5;
            letter-spacing: -0.96px;
            font-weight: bold;
            transition: color .16s; }
        &._open {
            color: $text-head;
            font-weight: bold;
            .t-information-disclosure__plus {
                span {
                    transform: rotate(135deg);
                    &:after {
                        transform: rotate(90deg); } } } }
        @media (min-width: 1024px) {
            &:hover {
                p {
                    color: $text-head; } } } }
    &__plus {
        margin-top: 4px;
        margin-left: auto;
        width: 32px;
        min-width: 32px;
        height: 32px;
        border: 2px solid $text-head;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            position: absolute;
            height: 18px;
            width: 2px;
            background-color: rgba($text-head, .5);
            transition: transform .3s;
            &:after {
                content: '';
                position: absolute;
                height: 18px;
                width: 2px;
                background-color: rgba($text-head, .5);
                transform: rotate(90deg);
                transition: transform .3s; } } }

    &__content {
        padding: 0 64px 0;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: all .5s;
        &._open {
            padding: 0 64px 24px;
            max-height: 2000px;
            opacity: 1; } }
    &__img {
        max-height: 3000px;
        width: 100%;
        img {
            margin: 0 auto;
            max-width: 100%;
            max-height: 950px;
            object-fit: contain;
            display: block; } } }

.t-info-block {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -2px;
        width: 56px;
        height: 43px;
        background: linear-gradient(to right, rgba(239, 248, 253, 0) 0%, rgba(239, 248, 253, 0.8) 80%, #eff8fd 100%);
        pointer-events: none; }
    &__wrap {
        overflow: hidden;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none; } }
    &__container {
        min-width: 100%;
        width: fit-content;
        display: flex;
        box-shadow: none; }
    &__item {
        margin-right: 40px;
        white-space: nowrap;
        margin-bottom: 12px;
        font-weight: bold;
        letter-spacing: -0.8px;
        line-height: 1.6;
        color: rgba(46, 53, 62, 0.6);
        font-size: 20px;
        transition: color .3s;
        cursor: pointer;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: -12px;
            left: 0;
            background-color: $text-head;
            opacity: 0;
            transition: opacity .3s; }
        &._active {
            color: $text-head;
            &:after {
                opacity: 1; } } }
    &__content {
        padding: 40px 0 0;
        box-sizing: content-box;
        position: relative;
        overflow: hidden;
        transition: height .5s;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(46, 53, 62, 0.1); } }
    &__text {
        padding-top: 40px;
        width: 100%;
        opacity: 1;
        transition: opacity .5s .3s;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        &._hidden-block {
            transition: opacity .5s;
            opacity: 0;
            z-index: 0; } }

    &__info {
        display: flex;
        margin-bottom: 32px;
        &:active {
            .t-info-block__link {
                color: $text-head-dark !important; } }
        @media (min-width: 1024px) {
            &:hover {
                .t-info-block__link {
                    color: $text-head; } } }
        &[href$=".png"] {
            .t-info-block__img {
                background: url("/img/icons/png.svg") center center no-repeat; } }
        &[href$=".eps"] {
            .t-info-block__img {
                background: url("/img/icons/eps.svg") center center no-repeat; } }
        &[href$=".pdf"] {
            .t-info-block__img {
                background: url("/img/icons/pdf.svg") center center no-repeat; } } }
    &__img {
        width: 32px;
        min-width: 32px;
        height: 32px;
        margin-right: 16px;
        background: url("/img/icons/file.svg") center center no-repeat;
        //display: flex
        //align-items: center
        //justify-content: center
        //img
        //    max-height: 100%
        //    max-width: 100%
 }        //    object-fit: contain
    &__link {
        display: block;
        transition: color .3s;
        &:active {
            color: $text-head-dark !important; }
        @media (min-width: 1024px) {
            &:hover {
                color: $text-head; } }
        //@media (max-width: 1024px)
 }        //    cursor: none
    &__subtext {
        display: block;
        margin-top: 8px;
        font-size: 14px;
        line-height: 1.43;
        color: rgba(46, 53, 62, 0.6); } }

@media (max-width: 1023px) {
    .t-information-disclosure {
        margin-top: 40px;
        &__content {
            padding: 0 32px;
            &._open {
                padding: 0 32px 16px; } }
        &__img {
            img {
                max-height: 750px; } } } }

@media (max-width: 767px) {
    .t-information-disclosure {
        margin-top: 32px;
        &__content {
            padding: 0;
            &._open {
                padding: 0 0 16px; } }
        &__item {
            &:after {
                width: calc(100% + 64px);
                left: -32px; } }
        &__head {
            padding: 24px 0;
            p {
                font-size: 20px; } }
        &__plus {
            margin-top: 2px;
            width: 28px;
            min-width: 28px;
            height: 28px;
            span, span:after {
                height: 16px; } }
        &__img {
            img {
                max-height: 650px; } } }

    .t-info-block {
        &__item {
            font-size: 16px;
            margin-right: 24px; }
        &__text {
            padding-top: 32px; }
        &__info {
            margin-bottom: 24px; } } }

@media (max-width: 599px) {
    .t-information-disclosure {
        margin-top: 24px;
        &__item {
            &:after {
                width: calc(100% + 48px);
                left: -24px; } }
        &__head {
            p {
                margin-right: 16px; } }
        &__img {
            img {
                max-height: 400px; } } }

    .t-info-block {
        &__text {
            padding-top: 24px; } } }
