.t-pressKit {
    padding: 40px 0 80px;
    &__container {
        margin-top: 64px;
        padding-left: 32px;
        max-width: 936px; }

    &__wrap {
        margin-top: 16px; }
    &__item {
        padding: 64px 0;
        display: flex;
        align-items: center;
        &:not(:last-child) {
            box-shadow: inset 0 -1px 0 0 rgba(46, 53, 62, 0.1); }
        &._contacts {
            flex-direction: column;
            align-items: flex-start; } }
    &__img {
        width: 100%;
        max-width: 320px;
        margin-right: 48px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; } }
    &__right {
        display: flex;
        flex-direction: column; }
    &__str {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 16px; }
        &:active {
            .t-pressKit__download {
                color: $text-head-dark !important; } }
        @media (min-width: 1024px) {
            &:hover {
                .t-pressKit__download {
                    color: $text-head-light; } } }
        &[href$=".png"] {
            .t-pressKit__icon {
                background: url("/img/icons/png.svg") center center no-repeat; } }
        &[href$=".eps"] {
            .t-pressKit__icon {
                background: url("/img/icons/eps.svg") center center no-repeat; } }
        &[href$=".pdf"] {
            .t-pressKit__icon {
                background: url("/img/icons/pdf.svg") center center no-repeat; } } }
    &__icon {
        width: 32px;
        height: 32px;
        background: url("/img/icons/file.svg") center center no-repeat; }
    &__download {
        margin: 0 8px 0 12px;
        color: $text-head;
        transition: color .3s; }
    &__size {
        color: $text-add; }

    &__subtitle {
        margin-bottom: 32px;
        font-weight: 500; } }


@media (max-width: 1023px) {
    .t-pressKit {
        padding: 24px 0 56px;
        &__container {
            margin-top: 40px; }
        &__img {
            max-width: 268px; } } }

@media (max-width: 767px) {
    .t-pressKit {
        padding: 16px 0 48px;
        &__container {
            margin-top: 32px;
            padding-left: 0; }
        &__img {
            max-width: 248px;
            margin-right: 32px; } } }

@media (max-width: 599px) {
    .t-pressKit {
        padding: 16px 0 0;
        &__container {
            margin-top: 24px; }
        &__wrap {
            margin-top: 0; }
        &__item {
            flex-direction: column;
            align-items: flex-start;
            padding: 32px 0; }
        &__img {
            max-width: 100%;
            margin-bottom: 32px; } } }
