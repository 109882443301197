.t-news {
    padding: 40px 0 110px;
    .t-btn {
        margin: 0 auto;
        &-container {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: all .2s;
            &._hide {
                opacity: 0;
                transform: translate3d(0, 50px, 0); } } }
    &__container {
        margin: 64px 0;
        display: flex;
        flex-wrap: wrap; }
    &__item {
        width: calc((100% - 32px*2)/3);
        margin-bottom: 32px;
        height: 320px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        border-radius: 2px;
        border: solid 1px rgba(46, 53, 62, 0.1);
        transition: all .2s;
        cursor: pointer;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        &._hide {
            opacity: 0;
            transform: translate3d(0, 50px, 0); }
        &:not(:nth-child(3n)) {
            margin-right: 32px; }
        &._img {
            border: none;
            .t-news__title {
                color: #ffffff; }
            .t-news__date {
                color: rgba(255, 255, 255, 0.6);
                box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1); }
            @media (min-width: 1024px) {
                &:hover {
                    .t-news__date {
                        color: #ffffff; } } } }
        @media (min-width: 1024px) {
            &:hover {
                box-shadow: 0 24px 40px -12px rgba(15, 37, 51, 0.5);
                border-radius: 2px 30px 2px 2px;
                .t-news__img {
                    background-size: auto 104%; }
                .t-news__title {
                    color: $text-head; }
                .t-news__date {
                    color: $text-base; } } } }


    &__img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-blend-mode: multiply;
        background-size: auto 100%;
        background-position: center center;
        transition: background-size .2s; }
    &__title {
        font-size: 24px;
        margin-bottom: auto;
        line-height: 1.5;
        letter-spacing: -0.96px;
        font-weight: bold;
        transition: color .16s; }
    &__date {
        font-size: 14px;
        color: rgba(46, 53, 62, 0.6);
        padding-top: 16px;
        box-shadow: inset 0 1px 0 0 rgba(46, 53, 62, 0.1);
        transition: color .16s; } }



.t-newsDetail {
    margin: 40px 0 56px;
    h1 {
        margin-bottom: 64px; }
    .t-work-area-big {
        & > .t-text {
            padding-left: 32px;
            max-width: 936px; } }
    &__container {
        margin-bottom: 80px; }
    &__content {
        width: 100%; }
    &__last {
        padding: 80px 100px 0; }
    &__img {
        height: 468px;
        width: 100%;
        border-radius: 0 30px 0 0;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;

            z-index: -1; }
        & + h1 {
            margin-top: -108px;
            max-width: 668px;
            padding: 40px 40px 0 0;
            background-color: #ffffff; } }
    &__date {
        padding-left: 32px;
        margin-top: 48px;
        font-size: 14px;
        line-height: 1.43;
        opacity: .6; } }


@media (max-width: 1263px) {
    .t-news {
        &__title {
            font-size: 23px; } }

    .t-newsDetail {
        &__last {
            padding: 80px 80px 0; }
        &__img {
            & + h1 {
                max-width: 528px; } } } }


@media (max-width: 1023px) {
    .t-news {
        padding: 24px 0 90px;
        &__container {
            margin: 40px 0 64px; }
        &__title {
            font-size: 24px; }
        &__item {
            width: calc((100% - 32px*1)/2);
            &:not(:nth-child(3n)) {
                margin-right: 32px; }
            &:nth-child(3n) {
                margin-right: 32px; }
            &:nth-child(2n) {
                margin-right: 0; } } }

    .t-newsDetail {
        margin: 24px 0 16px;
        h1 {
            margin-bottom: 40px; }
        &__container {
            margin-bottom: 56px; }
        &__last {
            padding: 56px 0 0;
            .t-news__item:nth-child(n + 3) {
                display: none; } } } }


@media (max-width: 767px) {
    .t-news {
        padding: 16px 0 70px;
        &__container {
            margin: 32px 0 56px; }
        &__title {
            font-size: 20px;
            margin-bottom: 16px;
            line-height: 1.6;
            letter-spacing: -0.8px; }
        &__item {
            margin-bottom: 24px;
            min-height: 232px;
            height: auto;
            width: calc((100% - 24px*1)/2);
            &:not(:nth-child(3n)) {
                margin-right: 24px; }
            &:nth-child(3n) {
                margin-right: 24px; }
            &:nth-child(2n) {
                margin-right: 0; } } }

    .t-newsDetail {
        margin: 16px 0;
        .t-work-area-big {
            & > .t-text {
                padding-left: 0; } }
        &__date {
            padding-left: 0; }
        h1 {
            margin-bottom: 32px; }
        &__last {
            padding: 48px 0 0; }
        &__img {
            height: 368px;
            & + h1 {
                margin-top: -88px;
                padding: 32px 32px 0 0;
                max-width: 442px; } } } }


@media (max-width: 599px) {
    .t-news {
        padding: 16px 0 56px;
        &__container {
            margin: 24px 0 32px; }
        &__item {
            margin-bottom: 16px;
            width: 100%;
            &:not(:nth-child(3n)) {
                margin-right: 0; }
            &:nth-child(3n) {
                margin-right: 0; }
            &:nth-child(2n) {
                margin-right: 0; } } }

    .t-newsDetail {
        h1 {
            margin-bottom: 24px; }
        &__last {
            padding: 32px 0 0; }
        &__date {
            margin-top: 24px; }
        &__img {
            height: 268px;
            & + h1 {
                margin-top: 0;
                padding: 24px 0 0;
                max-width: 100%; } }
        &__content {
            width: calc(100% + 48px);
            margin-left: -24px;
            padding: 0 24px;
            overflow: hidden;
            overflow-x: auto;
            .t-news__container {
                flex-wrap: nowrap;
                width: fit-content; }
            .t-news__item {
                min-width: 240px;
                margin-right: 24px; } } } }

@media (max-width: 1024px) {
    .t-news {
        &__title {
            font-size: 20px; } } }
