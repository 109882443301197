.government {
    margin-bottom: 80px;

    @media (max-width: 1023px) {
        margin-bottom: 56px; }

    @media (max-width: 767px) {
        margin-bottom: 48px; }

    @media (max-width: 599px) {
        margin-bottom: 32px; }

    &__title {
        margin-top: 40px;

        @media (max-width: 1023px) {
            margin-top: 24px; }

        @media (max-width: 767px) {
            margin-top: 16px; }

        h1 {
            font-size: 56px;
            font-weight: 700;
            line-height: 1.07em;
            letter-spacing: -0.03125em;

            @media (max-width: 1023px) {
                font-size: 40px;
                line-height: 1.2em; }

            @media (max-width: 599px) {
                font-size: 32px;
                line-height: 1.25em; } } }

    &__container {
        padding-left: 32px;
        padding-right: 32px;

        @media (max-width: 767px) {
            padding-left: 0px;
            padding-right: 0px; } }

    &__info {
        padding-top: 64px;
        padding-bottom: 40px;

        @media (max-width: 1023px) {
            padding-top: 40px; }

        @media (max-width: 599px) {
            padding-top: 24px;
            padding-bottom: 24px; } }

    &__accordion {
        @media (max-width: 599px) {
            margin-left: -24px;
            margin-right: -24px; } }

    &__accordion .spoiler:last-child {
        border: none; }

    &__accordion &__textblock p {
        margin-bottom: 1.3333em;

        @media (max-width: 599px) {
            margin-bottom: 0.8889em; } }

    &__textblock {
        font-size: 18px;
        line-height: 1.7778em;
        font-weight: 400;

        h2 {
            margin: 1em 0;
            font-weight: 400;
            font-size: 32px;
            line-height: 1.625em;
            letter-spacing: -0.025em;

            @media (max-width: 599px) {
                font-size: 24px;
                line-height: 1.6667em;
                margin-bottom: 0.6667em; } }

        h3 {
            margin: 1.6em 0 1.2em;
            font-weight: 700;
            font-size: 20px;
            line-height: 32px1.6em; }

        h4 {
            margin: 0.4444em 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.7778em; }

        p {
            margin-bottom: 1.7778em;

            @media (max-width: 599px) {
                margin-bottom: 0.8889em; } }

        ul, ol {
            list-style: none;
            margin-bottom: 1.3333em;
            display: flex;
            flex-direction: column;
            gap: 8px;

            li {
                position: relative; } }

        ul {
            padding-left: 16px;

            @media (max-width: 1023px) {
                padding-left: 0; }

            li {
                padding-left: 40px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0.8889em;
                    left: 0px;
                    width: 16px;
                    height: 4px;
                    background-color: $text-head;
                    transform: translateY(-80%); } } }

        a {
            display: inline-block;
            position: relative;
            padding-right: 16px;
            transition: .2s ease-in-out;
            color: $text-head;
            cursor: pointer; }

        &>*:first-child {
            margin-top: 0; }

        &>*:last-child {
            margin-bottom: 0; } } }
