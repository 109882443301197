footer {
    &._none {
        display: none; } }

.t-footer {
    padding: 80px 0 68px;
    display: flex;
    &__info {
        display: flex;
        flex-direction: column;
        span {
            font-size: 14px;
            color: rgba(46, 53, 62, 0.6); } }
    &__logo {
        height: 42px;
        margin-bottom: 24px;
        width: fit-content; }
    &__phone {
        margin-bottom: 4px;
        font-size: 24px;
        line-height: 1.17;
        font-weight: 500; }
    &-link {
        display: flex;
        margin: 32px 0 16px;
        &__item {
            margin-right: 16px;
            width: 32px;
            height: 32px;
            background-color: rgba($text-base, .08);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color .16s;
            img {
                max-width: 24px;
                max-height: 24px;
                object-fit: contain;
                filter: brightness(1) invert(0);
                transition: filter .16s; }
            &:active {
                background-color: $text-head-dark !important;
                img {
                    filter: brightness(0) invert(1); } }
            @media (min-width: 1024px) {
                &:hover {
                    background-color: $text-head;
                    img {
                        filter: brightness(0) invert(1); } } } } }

    &__version {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #38393C;
        opacity: 0.6;
        margin-bottom: 24px; }

    &-menu {
        display: flex;
        margin: 16px 0 0 auto;
        ul {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            &:not(:last-child) {
                margin-right: 80px; }
            li {
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                a {
                    font-size: 16px;
                    color: rgba(46, 53, 62, 0.6);
                    line-height: 1.5;
                    transition: color .16s;
                    &:active {
                        color: $text-head-dark !important; }
                    @media (min-width: 1024px) {
                        &:hover {
                            color: $text-head; } } }
                &:first-child {
                    margin-bottom: 24px;
                    a {
                        color: $text-base;
                        font-weight: 500;
                        &:active {
                            color: $text-head-dark !important; }
                        @media (min-width: 1024px) {
                            &:hover {
                                color: $text-head; } } } } }
            &:nth-child(1) {
                width: 224px; }
            &:nth-child(2) {
                width: 176px; }
            &:nth-child(3) {
                width: 208px; } } }


    &-collaboration {
        margin-left: -12px;
        padding: 4px 12px;
        border-radius: 16px;
        background-color: rgba($text-base, .08);
        color: rgba(46, 53, 62, 0.6);
        font-size: 16px;
        line-height: 1.5;
        border: none;
        outline: none;
        width: fit-content;
        cursor: pointer;
        transition: color .16s, background-color .16s;
        &:active, &:focus {
            background-color: $text-head-dark !important;
            color: #ffffff; }
        @media (min-width: 1024px) {
            &:hover {
                background-color: $text-head;
                color: #ffffff; } } } }



@media (max-width: 1263px) {
    .t-footer {
        &-menu {
            ul {
                width: auto;
                max-width: 200px;
                min-width: 176px;
                &:not(:last-child) {
                    margin-right: 32px; } } } } }


@media (max-width: 1023px) {
    .t-footer {
        padding: 56px 0 44px;
        flex-direction: column-reverse;
        &__info {
            align-items: center;
            margin-top: 48px; }

        &-menu {
            margin: 0;
            width: 100%;
            ul {
                width: 208px !important;
                max-width: 208px !important;
                min-width: auto;
                &:not(:last-child) {
                    margin-right: 24px; } } } } }


@media (max-width: 767px) {
    .t-footer {
        padding: 48px 0 36px;
        &__info {
            margin-top: 40px; }

        &-menu {
            ul {
                width: 163px; } } } }

@media (max-width: 599px) {
    .t-footer {
        padding: 32px 0 22px;
        &__info {
            align-items: flex-start;
            margin-top: 0; }

        &-menu {
            flex-direction: column;
            ul {
                margin-bottom: 24px;
                max-width: 100%;
                width: 100%;
                li {
                    margin-bottom: 10px;
                    &:first-child {
                        margin-bottom: 16px; } } } } } }
