.t-popup {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    max-width: 1200px;
    width: 100%;
    padding: 24px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 32px 40px 1px #27272514;
    z-index: 1;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    @media all and (max-width: 1279px) {
        max-width: 944px; }
    @media all and (max-width: 1023px) {
        bottom: 32px;
        max-width: 704px; }
    @media all and (max-width: 767px) {
        bottom: 24px;
        max-width: 552px; }
    @media all and (max-width: 599px) {
        bottom: 20px;
        max-width: 320px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
        padding: 20px; }

    &__text {
        color: #38393C;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px; }

    &__link {
        color: #41A6E4; }

    &__button {
        padding: 8px 16px;
        border: none;
        border-radius: 6px;
        color: white;
        background: #41A6E4;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer; }

    &.t-display-none {
        display: none; } }
