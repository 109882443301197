.t-main {
    margin-top: 76px;
    &__container {
        display: flex;
        align-items: flex-start;
        margin-bottom: 80px;
        overflow: hidden;
        h1 {
            margin-bottom: 40px; } }
    &__left {
        min-width: 568px;
        width: 568px;
        margin-right: 32px; }
    &__text {
        margin: 40px 0 32px; }
    &__img {
        min-width: 568px;
        width: 568px;
        img {
            display: block;
            object-fit: contain;
            max-width: 100%; } } }


.t-main-properties {
    //margin-bottom: 80px
    &__container {
        padding: 80px 0 0;
        display: flex;
        flex-wrap: wrap; }
    &__item {
        width: calc(50% - 32px);
        margin: 0 32px 80px 0;
        display: flex; }
    &__img {
        min-width: 96px;
        width: 96px;
        height: 96px;
        margin-right: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; } }
    &__content {
        margin-top: 24px;
        max-width: 404px;
        h2 {
            margin-bottom: 24px; } } }


.t-main-about {
    &__content {
        padding: 80px 100px 80px 132px; }
    &__title {
        margin-bottom: 40px;
        font-size: 72px;
        line-height: 1.34;
        letter-spacing: -2.57px; }
    &__container {
        display: flex; }
    &__left {
        max-width: 536px;
        margin-right: 36px; }
    &__img {
        max-width: 368px;
        img {
            max-width: 100%;
            object-fit: contain; } }
    &__text {
        white-space: pre-line;
        margin-bottom: 32px; } }



@media (max-width: 1263px) {
    .t-main {
        &__left {
            min-width: 528px;
            width: 528px; } } }

@media (max-width: 1023px) {
    .t-main {
        margin-top: 40px;
        &__left {
            min-width: 324px;
            width: 324px; }
        &__img {
            min-width: auto;
            width: auto;
            margin-right: 48px; }
        &__container {
            align-items: center;
            margin-bottom: 56px; }

        &-properties {
            margin-bottom: 24px;
            &__container {
                padding: 56px 0 0; }
            &__item {
                flex-direction: column;
                margin: 0 24px 48px 0;
                width: calc(50% - 24px); } }

        &-about {
            &__content {
                padding: 56px 0; }
            &__title {
                font-size: 56px;
                margin-bottom: 32px; }
            &__left {
                width: 324px;
                min-width: 324px;
                margin-right: 24px; } } } }


@media (max-width: 767px) {
    .t-main {
        margin-top: 16px;
        &__container {
            flex-direction: column-reverse;
            padding-right: 32px;
            margin-bottom: 48px; }
        &__img {
            margin: 0 auto 32px;
            max-width: 324px; }
        &__left {
            width: 100%;
            margin-right: 0;
            min-width: auto; }

        &-properties {
            margin-bottom: 0;
            &__container {
                padding: 48px 0 0; } }

        &-about {
            &__content {
                padding: 48px 0; }
            &__container {
                flex-direction: column; }
            &__left {
                width: 100%;
                margin-right: 0;
                min-width: auto; }
            &__img {
                margin: 40px auto 0; } } } }


@media (max-width: 599px) {
    .t-main {
        &__container {
            padding-right: 24px;
            margin-bottom: 32px;
            h1 {
                margin-bottom: 16px; } }
        &__text {
            margin: 16px 0 24px; }
        &__img {
            padding: 0 26px; }

        &-properties {
            margin-bottom: 8px;
            &__container {
                padding: 32px 0 0; }
            &__item {
                width: 100%;
                margin: 0 0 24px; } }

        &-about {
            &__content {
                padding: 32px 0; }
            &__title {
                font-size: 40px;
                margin-bottom: 16px; }
            &__text {
                margin-bottom: 24px; }
            &__img {
                margin: 32px 40px 0 0; } } } }
