.spoiler {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: block;
    background-color: #fff;
    border-bottom: 1px solid #EAEBEC;
    transition: all 0.2s linear;

    &__intro {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 32px;
        align-items: flex-start;
        cursor: pointer;
        padding: 40px 32px;

        @media (max-width: 767px) {
            padding: 24px;
            gap: 16px; } }

    &__title {
        color: $text-base;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.5em;
        letter-spacing: -0.04em;
        transition: all 0.2s linear;

        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 1.6em; } }

    &__icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $text-head;
        background-color: transparent;
        border: 2px solid $text-head;
        border-color: $text-head;
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        transition: all 0.2s;

        @media (max-width: 767px) {
            flex-basis: 28px;
            width: 28px;
            height: 28px; }

        svg {
            width: 18px;
            height: 18px;
            opacity: 0.5;

            @media (max-width: 767px) {
                width: 16px;
                height: 16px; } } }

    &.active {
        background-color: #F0F8FD;
        border-color: #F0F8FD; }

    &__intro:hover &__title {
        color: $text-head; }

    &.active &__icon {
        transform: rotate(45deg); }

    &__content {
        display: none;
        overflow: hidden;
        padding: 0 64px 40px;

        @media (max-width: 1023px) {
            padding: 0 32px 40px; }

        @media (max-width: 1023px) {
            padding: 0 24px 24px; } } }
