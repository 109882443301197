.t-feedback {
    padding: 40px 0 80px;
    &__container {
        padding-left: 32px;
        margin-top: 64px;
        max-width: 936px; }

    &__form {
        margin: 40px 0 0;
        max-width: 604px;
        display: flex;
        flex-direction: column;
        ._border-error-captсha {
            border: 1px solid $text-error; } }

    &__folder {
        margin-bottom: 32px;
        position: relative;
        opacity: 1;
        transition: opacity .16s, margin-bottom .16s;
        &-checkbox {
            margin-bottom: 32px;
            label {
                padding-left: 36px;
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: color .2s;
                span {
                    margin-left: 20px; }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 32px;
                    height: 32px;
                    border: 1px solid rgba(46, 53, 62, 0.1);
                    border-radius: 2px;
                    transition: border .2s; }

                svg {
                    position: absolute;
                    left: 9px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    opacity: 0;
                    transition: opacity .2s;
                    width: 18px;
                    height: 20px; } }
            &:hover {
                label:before {
                    border: 1px solid 1px solid rgba(46,53,62,.4); } }
            input {
                display: none;
                &:checked + label {
                    svg {
                        opacity: 1; } } }

            &._border-error-checkbox {
                label:before {
                    border: 1px solid $text-error; } } }

        &._valid {
            .t-feedback__input, .t-feedback__textarea {
                border: solid 1px $text-valid !important;
                &:focus {
                    box-shadow: 0 8px 40px -4px rgba(66, 122, 68, 0.24) !important; } }
            .t-feedback__icon._valid {
                opacity: 1; } }
        &._error {
            margin-bottom: 56px;
            .t-feedback__input, .t-feedback__textarea {
                border: solid 1px $text-error !important;
                &:focus {
                    box-shadow: 0 8px 40px -4px rgba(122, 66, 66, 0.24) !important; } }
            .t-feedback__icon._error, .t-feedback__text {
                opacity: 1; } }
        &._wait {
            opacity: .4;
            pointer-events: none; } }

    &__input, &__textarea {
        padding: 34px 64px 12px 24px;
        border: solid 1px rgba(46, 53, 62, 0.1);
        border-radius: 2px;
        transition: all .16s;
        cursor: pointer;
        width: 100%;
        color: $text-add;
        line-height: 1.78;
        font-size: 18px;
        outline: none;
        &:focus {
            border: solid 1px $text-head !important;
            box-shadow: 0 8px 40px -4px rgba(51, 130, 179, 0.24) !important; }
        @media (min-width: 1024px) {
            &:hover {
                border: solid 1px rgba(46, 53, 62, 0.4); } } }

    &__textarea {
        height: 240px;
        resize: none;
        display: block; }
    &__name {
        font-size: 18px;
        color: rgba(46, 53, 62, 0.6);
        line-height: 1.78;
        position: absolute;
        top: 24px;
        left: 24px;
        pointer-events: none;
        transition: all .16s;
        &._focus {
            top: 16px;
            font-size: 14px;
            line-height: 1.43; } }

    &__icon {
        opacity: 0;
        position: absolute;
        right: 24px;
        top: 28px;
        transition: opacity .16s; }
    &__text {
        opacity: 0;
        position: absolute;
        //bottom: -29px
        margin-top: 4px;
        left: 0;
        color: $text-error !important;
        font-size: 14px;
        line-height: 1.43;
        //transition: opacity .3s
        //&._success
        //    top: -24px
        //    bottom: auto
        //    color: $text-valid
        //&._error
        //    top: -24px
        //    bottom: auto
 }        //    width: max-content
    &__btn {
        margin-top: 8px;
        padding: 24px;
        color: #ffffff;
        cursor: pointer;
        background-color: $text-head;
        border-radius: 2px;
        transition: box-shadow .16s, background-color .16s;
        border: none;
        outline: none;
        font-size: 18px;
        line-height: 1.78;
        font-weight: 500;
        width: 280px;
        text-align: center;
        position: relative;
        &:active, &:focus {
            box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.08) !important;
            background-color: $text-head-dark !important; }
        @media (min-width: 1024px) {
            &:hover {
                box-shadow: 0 16px 40px -16px rgba(24, 164, 224, 0.76), 0 8px 16px -5px rgba(28, 105, 159, 0.2);
                background-color: $text-head-light; } }
        &._wait {
            pointer-events: none;
            .t-feedback__circle {
                opacity: 1; }
            span {
                opacity: 0; } }
        &._success {
            .t-feedback__text._success {
                opacity: 1; } }
        &._error {
            .t-feedback__text._error {
                opacity: 1; } } }

    &__circle {
        opacity: 0;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: #ffffff;
        background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 56%);
        position: absolute;
        top: calc(50% - 22px);
        left: calc(50% - 22px);
        animation: circle 1.7s infinite linear;
        transform: translateZ(0);
        &:after {
            content: '';
            background: $text-head-dark;
            width: 80%;
            height: 80%;
            border-radius: 50%;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0; }
        &:before {
            content: '';
            width: 50%;
            height: 50%;
            background: #ffffff;
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0; } } }

.t-feedback-success {
    position: fixed;
    bottom: 40px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(46, 53, 62, 0.2), 0 5px 10px 0 rgba(46, 53, 62, 0.15);
    border-radius: 84px;
    z-index: 10;
    transform: translateY(100px);
    transition: all .3s;
    p {
        margin-right: 12px;
        font-size: 16px;
        line-height: 1.5;
        text-align: center; }
    &__icon {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        object-fit: contain; }
    &__close {
        cursor: pointer; }
    &._success {
        left: calc(50% - 149px); }
    &._error {
        left: calc(50% - 215px); }

    &._open {
        transform: translateY(0); } }

@keyframes circle {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }

@media (max-width: 1023px) {
    .t-feedback {
        padding: 24px 0 56px;
        &__container {
            margin-top: 40px; }
        &__btn {
            padding: 16px 24px; } } }

@media (max-width: 767px) {
    .t-feedback {
        padding: 16px 0 48px;
        &__container {
            margin-top: 32px;
            padding-left: 0; }
        &__form {
            margin: 32px 0 0; }

        &__folder {
            margin-bottom: 16px;
            //&._error
 }            //  //margin-bottom: 38px
        &__input, &__textarea {
            padding: 28px 48px 6px 16px; }
        &__textarea {
            height: 204px; }
        &__name {
            top: 18px;
            left: 16px;
            &._focus {
                top: 10px; } }
        &__icon {
            right: 16px;
            top: 24px; }
        //&__text
        //  //bottom: -29px
        &__success {
            top: -21px; } } }

@media (max-width: 599px) {
    .t-feedback {
        padding: 16px 0 32px;
        &__container {
            margin-top: 24px; }
        &__form {
            margin: 24px 0 0; }
        &__text {
            font-size: 13px; }
        &__btn {
            width: 100%;
            text-align: center; } }

    .t-feedback-success {
        left: 24px !important;
        width: calc(100% - 48px);
        max-width: fit-content;
        bottom: 20px; } }
