header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &._fixed {
        .t-header {
            position: fixed;
            top: 0;
            width: 100%;
            box-shadow: 0 1px 0 0 rgba(46, 53, 62, 0.08);
            transform: translateY(-130px);
            transition: box-shadow .3s, transform 0s; }
        &._animate {
            .t-header {
                transform: translateY(-32px); } } }
    &._transition {
        .t-header {
            transition: box-shadow .3s, transform .3s; } }
    &._notSubmenu {
        height: 112px;
        .t-header-submenu {
            &:after {
                display: none; } } }
    &._none {
        display: none; } }


.t-header {
    background-color: #ffffff;
    transform: translateY(0px);
    transition: box-shadow .3s, transform .3s;
    z-index: 100;
    box-shadow: 0 0 0 0 rgba(46, 53, 62, 0.08);
    &__content {
        padding: 50px 40px 20px;
        max-width: 1366px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        align-items: center; }

    &-menu {
        display: flex;
        align-items: center;
        margin-left: auto;
        &__item {
            padding: 32px;
            display: flex;
            flex-direction: column;
            list-style-type: none;
            opacity: 0;
            background-color: #ffffff;
            position: absolute;
            top: calc(100% - 2px);
            left: 10px;
            pointer-events: none;
            box-shadow: 0 8px 40px -8px rgba(29, 74, 102, 0.2), 0 8px 24px -12px rgba(65, 166, 228, 0.2);
            z-index: 5;
            transition: opacity .16s;
            li {
                //padding: 5px
                &:not(:last-child) {
                    margin-bottom: 16px; }
                a {
                    color: $text-base !important;
                    white-space: nowrap;
                    transition: color .16s;
                    line-height: 1.5;
                    display: block;
                    @media (min-width: 1024px) {
                        &:hover {
                            color: $text-head !important; } } } }
            &._open {
                opacity: 1;
                pointer-events: auto; } } }

    nav > ul {
        list-style-type: none;
        display: flex;
        & > li {
            padding: 7px 10px 4px;
            font-size: 16px;
            margin-right: 20px;
            cursor: pointer;
            position: relative;
            & > a {
                padding-bottom: 6px;
                position: relative;
                white-space: nowrap;
                transition: color .16s;
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: -4px;
                    height: 2px;
                    background-color: $text-head;
                    opacity: 0;
                    transition: opacity .16s, bottom .16s; }
                &._active {
                    color: $text-head;
                    &:after {
                        bottom: 0;
                        opacity: 1; } } }
            //&:first-child a
            //    color: $text-head
            @media (min-width: 1024px) {
                &:hover {
                    a {
                        color: $text-head;
                        &:after {
                            bottom: 0;
                            opacity: 1; } } } } } }

    &-collaboration {
        padding: 8px 24px;
        margin-left: 10px;
        color: #ffffff;
        border-radius: 20px;
        background-color: $text-head;
        font-size: 16px;
        line-height: 1.5;
        cursor: pointer;
        transition: box-shadow .3s, background-color .3s;
        border: none;
        outline: none;
        &:active, &:focus {
            box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.08) !important;
            background-color: $text-head-dark !important; }
        @media (min-width: 1024px) {
            &:hover {
                box-shadow: 0 16px 40px -16px rgba(24, 164, 224, 0.76), 0 8px 16px -5px rgba(28, 105, 159, 0.2);
                background-color: $text-head-light; } } }

    &-submenu {
        //margin-top: auto
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 68px;
            height: 68px;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 80%, #ffffff 100%);
            pointer-events: none; }
        //&._none
        //    &:after
        //        display: none
        &__container {
            overflow: hidden;
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none; } }
        &__item {
            padding-top: 59px;
            padding-bottom: 19px;
            list-style-type: none;
            opacity: 1;
            display: flex;
            transition: opacity .3s;
            li {
                padding: 5px;
                font-size: 14px;
                margin-right: 30px;
                cursor: pointer;
                a {
                    display: block;
                    font-size: 14px;
                    line-height: 1.43;
                    color: rgba(46, 53, 62, 0.6);
                    white-space: nowrap;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 33.28px;
                        height: 24px;
                        transform: skew(-20deg);
                        //border-bottom: 24px solid rgba($text-head, .12)
                        //border-left: 24px solid transparent
                        //border-right: 24px solid transparent
                        left: -9px;
                        top: -3px;
                        background-color: rgba($text-head, .12);
                        opacity: 0;
                        transition: opacity .3s; }
                    &._active {
                        color: $text-head !important;
                        &:after {
                            opacity: 1; } }
                    @media (min-width: 1024px) {
                        &:hover {
                            color: $text-head; } } }
                &:last-child {
                    padding-right: 32px; } } } } }

.t-logo {
    height: 42px;
    z-index: 3;
    svg {
        overflow: visible; }
    &__sign {
        transform: translate(0, 0) scale(1);
        transform-origin: 20px 20px;
        transition: transform .2s; }
    &__text {
        transition: fill .16s; }
    @media (min-width: 1024px) {
        &:hover {
            .t-logo__sign {
                transform: translate(0, 0) scale(1.1); }
            .t-logo__text {
                fill: $text-head; } } } }


.t-burger {
    display: none;
    width: 24px;
    height: 24px;
    padding: 5px 2px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    z-index: 3;
    &._open {
        span {
            background-color: #ffffff;
            &:before {
                transform: rotate(135deg) translateY(0); }
            &:after {
                transform: rotate(-135deg) translateY(0); } } }
    span {
        position: relative;
        width: 20px;
        height: 2px;
        background-color: #5e656a;
        transition: background-color 0s;
        &:before, &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: #5e656a;
            transition: transform .3s; }
        &:before {
            transform: translateY(-6px); }
        &:after {
            transform: translateY(6px); } } }



@media (max-width: 1263px) {
    .t-header {
        &-menu {
            &__item {
                //top: calc(100% - 8px)
                left: 5px; } }

        nav > ul {
            & > li {
                padding: 7px 5px 4px;
                margin-right: 15px; } }

        &-collaboration {
            margin-left: 5px; } } }


@media (max-width: 1023px) {
    .t-burger {
        display: flex; }

    .t-header {
        &__content {
            padding: 28px 24px 20px; }
        &-menu {
            position: fixed;
            top: 0;
            left: 0;
            opacity: 0;
            pointer-events: none;
            width: 100%;
            background-color: #ffffff;
            min-height: calc(100vh + 8px);
            flex-direction: column;
            padding: 170px 24px 60px;
            align-items: flex-start;
            transition: opacity .4s;
            &._open {
                opacity: 1;
                pointer-events: auto;
                z-index: 2; } }

        nav ul {
            flex-direction: column;
            li {
                margin-bottom: 12px;
                &:first-child a {
                    color: $text-base; } } }

        &-collaboration {
            margin-top: 80px; }

        &-submenu {
            &__item {
                padding-top: 36px; } } }

    header {
        height: 164px;
        &._fixed {
            &._animate {
                .t-header {
                    transform: translateY(-8px); } } }
        &._notSubmenu {
            height: 80px; } }

    .t-logo {
        height: 32px;
        width: 104px;
        svg {
            max-height: 100%;
            max-width: 100%; } } }


@media (max-width: 767px) {
    .t-header {
        &-submenu {
            &__item {
                padding-top: 19px;
                li {
                    margin-right: 16px; } } } }

    header {
        height: 148px; } }

@media (max-width: 599px) {
    .t-header {
        &-submenu {
            &__item {
                padding-top: 11px;
                padding-bottom: 11px; } } }

    header {
        height: 132px; } }


@media (orientation : landscape) and (max-device-width : 1023px) {
    .t-header-menu {
        padding: 76px 24px 60px;
        justify-content: center; }
    .t-header nav ul {
        //flex-wrap: wrap
        //height: 120px
        column-count: 2;
        display: block; }
    .t-header nav ul li {
        margin-bottom: 0;
        margin-right: 60px; }
    .t-header nav>ul>li {
        padding: 4px 5px 4px; }
    .t-header-collaboration {
        margin-top: 10px; } }

