.t-about {
    padding: 40px 0 80px;
    .t-text {
        padding-left: 32px;
        max-width: 936px; }
    &__container {
        margin: 64px 0 40px 32px;
        display: flex;
        align-items: center;
        .t-projects-block__content {
            //max-width: 548px
            margin: 0 0 0 -64px;
            span {
                margin-top: 24px;
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.25;
                letter-spacing: 2px;
                color: rgba(46, 53, 62, 0.6);
                text-transform: uppercase; } } }

    &__text-wrap {
        padding: 0 40px; }

    &__img {
        display: none;
        margin: 0 -100px 0 40px;
        width: 568px;
        max-height: 378px;
        //display: flex
        justify-content: center;
        align-items: center;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: -32px;
            left: 32px;
            width: 404px;
            height: 64px;
            background-color: rgba($text-head, .5);
            background-blend-mode: multiply; }
        img {
            max-height: 100%;
            max-width: 100%;
            object-fit: cover; } }

    &__item {
        padding: 40px 32px;
        display: flex;
        align-items: center;
        p {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.5;
            letter-spacing: -0.96px;
            transition: color .3s; }
        img {
            margin-left: auto; }
        &:not(:last-child) {
            box-shadow: inset 0 -1px 0 0 rgba(46, 53, 62, 0.1); }
        @media (min-width: 1024px) {
            &:hover {
                p {
                    color: $text-head; } } } }

    &__achievements {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        gap: 24px;
        padding: 40px 32px 0;

        &-card {
            width: 100%;
            height: 100%;
            list-style: none; }

        &-link {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            height: 100%;
            cursor: pointer;
            background: #FAFAFB;
            padding: 40px;
            transition: all 0.3s ease;

            &:hover {
                background: linear-gradient(0deg, #EFF8FD 0%, #EFF8FD 100%);

                .t-about__achievements-name {
                    color: #41A6E4; } } }

        &-img {
            position: relative;
            width: 100%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                object-fit: contain;
                width: max-content;
                height: auto;
                transform: scale(0.5) translateX(-50%); } }

        &-wrap {
            display: flex;
            flex-direction: column;
            min-height: 140px; }

        hr {
            position: relative;
            border: none;
            height: 1px;
            background: rgba(46, 53, 62, 0.10); }

        &-name {
            height: 96px;
            font-size: 20px;
            line-height: 32px;
            font-weight: 400;
            color: #38393C;
            font-family: 'Rubik', 'Arial', sans-serif;
            transition: all 0.3s ease; }

        &-desc {
            height: 54px;
            margin-top: 24px;
            color: #38393C;
            font-family: 'Rubik', 'Arial', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px; }

        &-awards {
            display: flex;
            flex-wrap: wrap;
            height: 42px;
            list-style: none;
            gap: 0 3px; }

        &-item {
            position: relative;
            width: max-content;
            color: rgba(46, 53, 62, 0.60);
            font-family: 'Rubik', 'Arial', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            &:not(:last-child) {
                &:before {
                    position: absolute;
                    right: -2px;
                    content: ", ";
                    bottom: 0; } } } }

    &__wrap {
        margin: 40px 0 0; } }

@media (max-width: 1263px) {
    .t-about {
        &__container {
            margin: 64px 0 40px;
            .t-projects-block__content {
                max-width: calc(100% + 32px);
                width: calc(100% + 32px);
                margin: 0 0 0 -32px;
                span {
                    display: none; } } }

        &__text-wrap {
            padding: 0 32px; }

        &__img {
            display: none; }

        &__achievements {
            grid-template-columns: 1fr;

            &-wrap {
                min-height: auto; }

            &-name {
                margin: 0 0 24px; } } } }

@media (max-width: 1023px) {
    .t-about {
        padding: 24px 0 56px;
        .t-text {
            max-width: 608px; }
        &__container {
            margin: 48px 0 40px;
            .t-projects-block__content {
                padding: 0 0 0 64px; }
            .t-projects-block__text {
                margin: 0 0 0 64px; } }

        &__achievements {
            padding: 40px 32px 0; } } }

@media (max-width: 767px) {
    .t-about {
        padding: 16px 0 48px;
        .t-text {
            padding-left: 0; }
        &__container {
            margin: 40px 0 32px;
            .t-projects-block__content {
                padding: 0 0 0 32px; }
            .t-projects-block__text {
                margin: 0 0 0 32px; } }
        &__item {
            padding: 24px 0;
            p {
                font-size: 20px;
                letter-spacing: -0.8px; } }

        &__text-wrap {
            padding: 0; }

        &__achievements {
            padding: 32px 0 0;

            &-link {
                padding: 24px; }

            &-name {
                margin: 0 0 16px; }

            &-desc {
                margin: 16px 0 0; } }

        &__wrap {
            margin: 24px 0 0; } } }

@media (max-width: 599px) {
    .t-about {
        padding: 16px 0 32px;
        &__container {
            margin: 32px 0 16px; }

        &__achievements {
            padding: 24px 0 0; }

        &__wrap {
            margin: 8px 0 0; } } }
