.t-legal {
    padding-top: 40px;
    h3 {
        line-height: 1.25; }
    &__wrap {
        margin: 64px 0 80px; }
    &__container {
        margin: 40px 0 0;
        padding-left: 32px; }
    &__item {
        margin-bottom: 32px; }
    &__title, &__text {
        margin-bottom: 8px;
        font-size: 14px !important;
        line-height: 1.43;
        color: rgba(46, 53, 62, 0.6) !important;
 }        //white-space: pre-line
    &__right {
        float: right;
        width: 300px;
        padding: 32px;
        margin-left: 32px;
        margin-bottom: 80px;
        box-shadow: inset 1px 0 0 0 rgba(46, 53, 62, 0.1);
        .t-legal__item {
            margin-bottom: 24px; }
        .t-legal__title {
            margin-bottom: 0; }
        .t-text {
            font-size: 14px;
            line-height: 1.43; }
        & + .t-security__container {
            max-width: 604px; } }
    &__content {
        padding: 80px 100px 64px; }
    &__text {
        margin-bottom: 16px;
        padding-left: 32px;
        line-height: 1.71; } }


@media (max-width: 1263px) {
    .t-legal {
        &__content {
            padding: 80px 80px 64px; }
        &__right {
            width: 288px;
            & + .t-security__container {
                max-width: 416px; } } } }

@media (max-width: 1023px) {
    .t-legal {
        padding-top: 24px;
        h3 {
            font-size: 24px; }
        &__wrap {
            margin: 40px 0 56px; }
        &__container {
            margin: 32px 32px 0 0; }
        &__right {
            width: 266px;
            padding: 24px;
            margin-left: 24px;
            margin-bottom: 150px;
            & + .t-security__container {
                max-width: 100%; } }
        &__content {
            padding: 56px 0 24px; }
        &__text {
            padding-right: 32px; } } }

@media (max-width: 767px) {
    .t-legal {
        padding-top: 16px;
        &__wrap {
            margin: 32px 0 48px; }
        &__container {
            margin: 24px 0 0 0;
            padding-left: 0;
            display: flex;
            flex-direction: column; }
        &__item {
            margin-bottom: 24px; }
        &__right {
            order: 3;
            float: none;
            width: 100%;
            padding: 24px 0 0;
            margin-left: 0;
            margin-bottom: 0;
            box-shadow: inset 0 1px 0 0 rgba(46, 53, 62, 0.1); }
        &__content {
            padding: 48px 0 24px; }
        &__text {
            padding: 0; } } }

@media (max-width: 767px) {
    .t-legal {
        h3 {
            font-size: 20px;
            line-height: 1.6; }
        &__wrap {
            margin: 24px 0 32px; }
        &__right {
            .t-legal__item {
                margin-bottom: 16px; } }
        &__content {
            padding: 32px 0 8px; } } }

