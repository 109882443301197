.tabs-block {
    width: 100%;
    overflow: hidden;
    max-width: 904px;

    &__tabs {
        display: flex;
        align-items: stretch;
        gap: 40px;
        overflow-x: auto;
        border-bottom: 1px solid rgba(46, 53, 62, 0.1);

        &::-webkit-scrollbar {
            width: 3px;
            height: 3px; }

        &::-webkit-scrollbar-track {
            background-color: transparent; }

        &::-webkit-scrollbar-thumb {
            background-color: #41A6E4;
            border-radius: 1.5px; }

        @media (max-width: 600px) {
            gap: 24px; } }

    &__tab {
        width: fit-content;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 8px;
        cursor: pointer;
        font-family: Rubik, Arial, sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 1em;
        letter-spacing: -0.04em;
        color: rgba(46, 53, 62, 0.6);
        background-color: transparent;
        border-bottom: 4px solid transparent;
        border-color: transparent;
        transition: color 0.3s, border-color 0.3s;

        @media (max-width: 600px) {
            padding: 0 0 12px;
            font-size: 16px; }

        &--active {
            color: #41A6E4;
            border-color: #41A6E4; } }

    &__slides {
        margin-top: 0px; }

    &__slide {
        display: none;

        &--active {
            display: block; } }

    .t-about__achievements {
        padding: 24px 0 0;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @media (max-width: 1024px) {
            grid-template-columns: minmax(0, 1fr); } } }
