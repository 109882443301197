.t-security {
    .t-text {
        white-space: pre-line; }
    h1 {
        margin-bottom: 64px; }
    .t-legal__content {
        &:nth-child(1) {
            padding-top: 0;
            padding-bottom: 0; }
        &:nth-child(2) {
            box-shadow: none;
            padding-top: 0;
            .t-security__img {
                margin-bottom: 16px; } } }
    &__content {
        margin-bottom: 56px; }
    &__text {
        white-space: pre-line;
        color: rgba(56, 57, 60, 0.6);
        &._small {
            font-size: 14px;
            line-height: 1.43;
            color: $text-base; }
        a {
            color: $text-head;
            transition: color .3s;
            &:active {
                color: $text-head-dark !important; }
            @media (min-width: 1024px) {
                &:hover {
                    color: $text-head-light; } }
            //@media (max-width: 1024px)
 } }            //    cursor: none
    &__img {
        margin: 24px 0 0;
        max-width: 604px;
        img {
            max-width: 100%;
            object-fit: contain;
            display: block; } }
    &__container {
 }        //max-width: 604px

    &__imgRight {
        margin-bottom: 24px;
        max-width: 100%;
        img {
            max-width: 100%;
            object-fit: contain;
            display: block;
            max-height: 600px; } } }



@media (max-width: 1263px) {
    .t-security {
        &__container {
 } } }            //max-width: 416px

@media (max-width: 1023px) {
    .t-security {
        &__container {
 }            //max-width: 100%
        h1 {
            margin-bottom: 40px; }
        .t-legal__content {
            padding-bottom: 56px; }
        .t-legal__container {
            display: flex;
            flex-direction: column; }
        .t-legal__right {
            order: 3;
            float: none;
            width: 100%;
            padding: 24px 0 0;
            margin-left: 0;
            margin-bottom: 0;
            box-shadow: inset 0 1px 0 0 rgba(46, 53, 62, 0.1); } } }

@media (max-width: 767px) {
    .t-security {
        h1 {
            margin-bottom: 32px; }
        h3 {
            font-size: 24px; }
        &__content {
            margin-bottom: 48px; }
        .t-legal__content {
            padding-bottom: 48px; } } }

@media (max-width: 599px) {
    .t-security {
        h1 {
            margin-bottom: 24px; }
        h3 {
            font-size: 20px; }
        .t-legal__container {
            margin: 16px 0 0; }
        &__content {
            margin-bottom: 32px; }
        .t-legal__content {
            padding-bottom: 32px; } } }
