.t-projects {
    padding: 40px 0 64px;
    &._img {
        padding-top: 0; }
    h1 {
        margin: 0 0 64px;
        &._img {
            margin-top: -108px;
            padding: 40px 40px 10px 0;
            min-width: 668px;
            width: 668px;
            //width: fit-content
            background-color: #ffffff; } }
    .t-work-area-big {
        & > .t-text {
            padding-left: 32px;
            &:last-child {
                margin-bottom: 16px; } } }


    &__container {
        margin: 40px 0 0 32px;
        display: flex;
        flex-wrap: wrap; }
    &__item {
        padding: 24px 0 32px;
        width: calc(50% - 32px);
        display: flex;
        margin-bottom: 16px;
        margin-right: 32px; }
    &__icon {
        margin: 0 24px 8px 0;
        width: 48px;
        min-width: 48px;
        height: 48px;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; } }
    &__right {
        margin-top: 8px;
        max-width: 340px;
        .t-text {
            margin-top: 8px; } }
    &__title {
        //margin-bottom: 8px
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.96px;
        line-height: 1.5; }

    &__img {
        width: 100%;
        height: 680px;
        position: relative;
        display: flex;
        justify-content: center;
        img {
            display: block;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            z-index: -1; } }

    &-block {
        margin: 56px 0;
        display: flex;
        align-items: flex-start;
        &__container {
            margin-top: 8px;
            width: 268px;
            min-width: 268px;
            box-shadow: inset -1px 0 0 0 rgba(46, 53, 62, 0.1);
            display: flex;
            flex-direction: column; }
        &__item {
            font-size: 20px;
            line-height: 1.6;
            letter-spacing: -0.8px;
            color: rgba(46, 53, 62, 0.6);
            font-weight: bold;
            cursor: pointer;
            transition: color .3s;
            &:not(:last-child) {
                margin-bottom: 24px; }
            &:after {
                content: '';
                position: absolute;
                width: 4px;
                height: 100%;
                top: 0;
                right: 0;
                background-color: $text-head;
                opacity: 0;
                transition: opacity .3s; }
            &._active {
                position: relative;
                color: $text-head;
                &:after {
                    opacity: 1; } } }

        &__content {
            padding-left: 64px;
            position: relative;
            width: 100%;
            overflow: hidden;
            transition: height .5s;
            h3 {
                font-weight: normal;
                line-height: 1.63;
                letter-spacing: -0.8px; }
            &._one {
                margin: 48px 0 32px -32px;
                height: auto !important;
                overflow: visible;
                &:after {
                    display: none; }
                .t-projects-block__quotes {
                    position: absolute; }
                .t-projects-block__text {
                    margin: 0;
                    position: relative; } }
            .t-projects-block__content._one {
                margin: 0 0 32px 0;
                padding: 0; } }
        &__text {
            margin-left: 64px;
            opacity: 1;
            transition: opacity .5s .3s;
            position: absolute;
            top: 0;
            left: 0;
            &._hidden-block {
                transition: opacity .5s;
                opacity: 0; } }
        &__quotes {
            display: flex;
            margin: -6px 0 0 -11px; }
        &__q {
            width: 15px;
            height: 28px;
            transform: skew(-40deg);
            background-color: rgba($text-head, .08);
            &:after {
                content: '';
                width: 15px;
                height: 28px;
                //transform: skew(-30deg)
                background-color: rgba($text-head, .08);
                position: absolute;
                top: 100%;
                left: 24px;
                transform: skew(60deg); }
            &:last-child {
                margin-left: 15px; } } } }


@media (max-width: 1263px) {
    .t-projects {
        &__img {
            height: 520px; }
        h1._img {
            min-width: 528px;
            width: 528px;
            padding: 43px 40px 0 0; } } }

@media (max-width: 1023px) {
    .t-projects {
        padding: 24px 0 56px;
        h1 {
            margin: 0 0 40px;
            &._img {
                padding: 43px 40px 8px 0;
                margin-top: -97px;
                min-width: 556px;
                width: 556px; } }

        .t-work-area-big {
            & > .t-text {
                padding-left: 0; } }

        &__item {
            padding: 24px 0;
            margin-bottom: 0;
            flex-direction: column; }

        &__img {
            height: 400px; }

        &-block {
            margin: 48px 0;
            flex-direction: column;
            &__wrap {
                width: calc(100% + 96px);
                min-width: calc(100% + 96px);
                margin-left: -48px;
                padding-left: 48px;
                overflow: hidden;
                overflow-x: auto;
                &::-webkit-scrollbar {
                    display: none; } }
            &__container {
                min-width: 100%;
                width: fit-content;
                flex-direction: row;
                box-shadow: none;
 }                //box-shadow: inset 0 -1px 0 0 rgba(46, 53, 62, 0.1)
            &__item {
                margin-right: 24px;
                white-space: nowrap;
                &:not(:last-child) {
                    margin-bottom: 12px; }
                &:last-child {
                    margin-bottom: 12px;
 }                    //padding-right: 32px
                &:after {
                    bottom: -12px;
                    left: 0;
                    top: auto;
                    width: 100%;
                    height: 4px; } }
            &__content {
                padding: 48px 48px 0 32px;
                //box-shadow: inset 0 1px 0 0 rgba(46, 53, 62, 0.1)
                box-sizing: content-box;
                width: calc(100% - 32px);
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 48px;
                    width: 100%;
                    height: 1px;
                    background-color: rgba(46, 53, 62, 0.1); }
                &._one {
                    margin: 0 0 32px;
                    padding: 48px 0 0;
                    .t-projects-block__text {
                        margin: 0; } } }
            &__text {
                margin: 48px 48px 0 32px; } } } }


@media (max-width: 767px) {
    .t-projects {
        padding: 16px 0 48px;
        h1 {
            margin: 0 0 32px;
            &._img {
                min-width: 440px;
                width: 440px; } }

        &__container {
            margin: 24px 0 0; }

        &__item {
            padding: 16px 0;
            margin-right: 24px;
            width: calc(50% - 12px);
            &:nth-child(even) {
                margin-right: 0; } }
        &__title {
            font-size: 20px; }

        &__img {
            height: 320px; }

        &-block {
            &__wrap {
                width: calc(100% + 64px);
                min-width: calc(100% + 64px);
                margin-left: -32px;
                padding-left: 32px; }
            &__content {
                padding: 40px 64px 0 32px;
                margin-left: -32px;
                &._one {
                    margin: 0 0 32px -32px;
                    padding: 40px 0 0 32px; } }
            &__text {
                margin: 48px 32px 0 32px; } } } }


@media (max-width: 599px) {
    .t-projects {
        h1 {
            margin: 0 0 24px;
            &._img {
                min-width: 100%;
                width: 100%;
                margin-top: 0;
                padding: 24px 0 0; } }

        &__container {
            margin: 16px 0 0; }

        &__item {
            margin-right: 0;
            width: 100%; }

        &__img {
            height: 240px; }

        &-block {
            margin: 40px 0 24px;
            &__wrap {
                width: calc(100% + 56px);
                min-width: calc(100% + 56px);
                margin-left: -32px;
                padding-left: 32px; }
            &__content {
                width: 100%;
                padding: 48px 24px 0 32px;
                margin-left: -32px;
                &._one {
                    padding: 32px 0 0 32px; } }
            &__text {
                margin: 48px 24px 0 32px; } } } }
