.t-contacts {
    h1 {
        margin-bottom: 64px; }
    .t-text {
        white-space: pre-line; }
    .t-legal__container {
        margin-top: 48px; }
    .t-legal__container + h2 {
        margin-top: 80px; }
    &__phone {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 1.6;
        letter-spacing: -0.8px;
        font-weight: bold;
        display: block; }
    &__email {
        margin-top: 8px;
        color: $text-head;
        display: block;
        transition: color .3s;
        &:active {
            color: $text-head-dark !important; }
        @media (min-width: 1024px) {
            &:hover {
                color: $text-head-light; } }
        //@media (max-width: 1024px)
 }        //    cursor: none
    &__link {
        color: $text-head;
        transition: color .3s;
        &:active {
            color: $text-head-dark !important; }
        @media (min-width: 1024px) {
            &:hover {
                color: $text-head-light; } } }
    &__title {
        margin-top: 24px;
        font-weight: 500;
        &:first-child {
            margin-top: 0; } }
    &__item {
        display: flex;
        margin-top: 32px; }
    &__icon {
        width: 32px;
        min-width: 32px;
        height: 32px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; } }
    &__text {
        color: $text-add;
        white-space: normal !important;
        &._color {
            color: rgba(56, 57, 60, 0.6); } }
    &__subtext {
        margin-bottom: 16px;
        font-size: 16px;
        color: rgba(56, 57, 60, 0.6); } }

.t-map {
    margin: 64px 0 80px;
    width: 100%;
    height: 368px;
    border-radius: 2px 40px 2px 2px;
    overflow: hidden;
    #map {
        width: 100%;
        height: 100%; }
    &__ctrl {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;
        pointer-events: none;
        &_text {
            position: relative;
            top: 50%;
            left: 0;
            right: 0;
            color: white;
            text-align: center;
            font-size: 20px;
            pointer-events: none; } } }

@media (max-width: 1263px) {
    .t-contacts {
        .t-legal__right {
            width: 246px; }
        .t-security__container {
            max-width: 460px; } } }

@media (max-width: 1023px) {
    .t-contacts {
        h1 {
            margin-bottom: 0; }
        .t-legal__container {
            margin: 40px 0 0; }
        .t-legal__container + h2 {
            margin-top: 56px; }
        .t-security__container {
            max-width: 362px; } }

    .t-map {
        margin: 48px 0 56px; } }

@media (max-width: 767px) {
    .t-contacts {
        .t-legal__right {
            margin-top: 32px;
            width: 100%; }
        .t-legal__container {
            margin: 32px 0 0; }
        .t-legal__container + h2 {
            margin-top: 48px; }
        .t-security__container {
            max-width: 100%; } }

    .t-map {
        margin: 32px 0 48px;
        height: 268px; } }

@media (max-width: 599px) {
    .t-contacts {
        &__item {
            margin-top: 24px; }
        .t-legal__right {
            margin-top: 24px; }
        .t-legal__container {
            margin: 24px 0 0; }
        .t-legal__container + h2 {
            margin-top: 32px; } }

    .t-map {
        margin: 24px 0 32px; } }
