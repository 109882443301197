.t-management {
    padding-top: 40px;
    &__content {
        margin: 64px 0 48px;
        padding-left: 32px;
        .t-text {
            max-width: 936px; } }
    &__container {
        margin-top: 48px;
        display: flex;
        flex-wrap: wrap; }
    &__item {
        width: 202px;
        margin-bottom: 32px;
        &:not(:last-child) {
            margin-right: 32px; } }
    &__img {
        margin-bottom: 24px;
        width: 168px;
        height: 168px;
        background-color: #f9f9fc;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            max-height: 100%;
            object-fit: cover; } }
    &__name {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        white-space: pre-line; }
    &__position {
        font-size: 14px;
        line-height: 1.43;
        color: rgba(46, 53, 62, 0.6); }

    &__wrap {
        padding: 80px 100px 48px;
        .t-management__container {
            padding-left: 32px; } } }

@media (max-width: 1263px) {
    .t-management {
        &__wrap {
            padding: 80px 80px 48px; } } }

@media (max-width: 1023px) {
    .t-management {
        padding-top: 24px;
        &__content {
            margin: 40px 0 24px; }
        &__container {
            margin-top: 40px; }
        &__item {
            width: 181px; }

        &__wrap {
            padding: 56px 0 24px; } } }

@media (max-width: 767px) {
    .t-management {
        padding-top: 16px;
        &__content {
            margin: 32px 0 16px;
            padding-left: 0; }
        &__container {
            margin-top: 32px; }
        &__item {
            width: 162px;
            &:not(:last-child) {
                margin-right: 24px; }
            &:nth-child(3n) {
                margin-right: 0; } }
        &__img {
            width: 162px;
            height: 162px; }
        &__wrap {
            padding: 48px 0 16px;
            .t-management__container {
                padding-left: 0; } } } }

@media (max-width: 599px) {
    .t-management {
        &__content {
            margin: 24px 0 8px; }
        &__container {
            margin-top: 24px; }
        &__item {
            width: 240px;
            margin-bottom: 24px;
            margin-right: 24px !important; }
        &__img {
            margin-bottom: 16px;
            width: 168px;
            height: 168px; }
        &__wrap {
            padding: 32px 0 8px; } } }
