@font-face {
    font-family: 'rubik';
    src: url('/fonts/rubik-bold.woff2') format('woff2'), url('/fonts/rubik-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal; }
@font-face {
    font-family: 'rubik';
    src: url('/fonts/rubik-medium.woff2') format('woff2'), url('/fonts/rubik-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal; }
@font-face {
    font-family: 'rubik';
    src: url('/fonts/rubik-regular.woff2') format('woff2'), url('/fonts/rubik-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal; }

body {
    min-width: 320px;
    min-height: 100vh;
    margin: 0;
    font-family: 'rubik', Arial, sans-serif;
    font-size: 18px;
    color: $text-base;
    line-height: 1.78;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    position: relative;
    display: flex;
    flex-direction: column;
    &.lock-scroll {
        position: fixed;
        width: 100%;
        height: 100%; } }

main {
    flex-grow: 1;
    padding-top: 220px;
    &._notSubmenu {
        padding-top: 112px; }
    &._404 {
        padding-top: 0; } }

.t-preloader {
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    z-index: 100;
    transition: opacity .5s;
    &._close {
        opacity: 0;
        pointer-events: none; } }

.load {
    opacity: 0;
    transition: opacity .5s .0s;
    &._load {
        opacity: 1;
        transition: opacity .6s 0s; } }

.t-animate {
    opacity: 1;
    transition: opacity .4s cubic-bezier(.25,.1,.25,1), transform .4s cubic-bezier(.25,.1,.25,1);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    &.hide {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        -webkit-transform: translate3d(0, 50px, 0);
        .t-animate-item {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
            -webkit-transform: translate3d(0, 50px, 0); } }
    &-item {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transition: opacity .2s cubic-bezier(.25,.1,.25,1), transform .2s cubic-bezier(.25,.1,.25,1) !important;
        -webkit-backface-visibility: hidden;
        @for $i from 1 through 50 {
            &:nth-child(#{$i}) {
                transition-delay: #{ .1 + 0.15*$i }s !important; } } } }

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0; }

a {
    color: $text-base;
    text-decoration: none; }

h1, h2, h3 {
    font-weight: bold;
    font-style: normal; }
h1 {
    font-size: 56px;
    line-height: 1.08;
    letter-spacing: -1.75px; }
h2 {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -1.2px; }
h3 {
    font-size: 32px;
    line-height: 1.67;
    letter-spacing: -0.96px; }

.t-work-area {
    padding: 0 99px;
    max-width: 1366px;
    width: 100%;
    margin: 0 auto; }
.t-work-area-big {
    padding: 0 199px;
    max-width: 1366px;
    width: 100%;
    margin: 0 auto; }
.t-work-area-big-fullRight {
    padding: 0 0 0 199px;
    margin: 0 0 0 calc((100% - 1366px)/2);
    max-width: calc((100% - 1366px)/2 + 1366px); }
.t-work-area-fullRight {
    padding: 0 0 0 99px;
    margin: 0 0 0 calc((100% - 1366px)/2);
    max-width: calc((100% - 1366px)/2 + 1366px); }

.t-btn {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    text-transform: uppercase;
    transform: translateX(0);
    transition: transform .2s ease-in-out;
    transition-delay: 0s !important;
    &._arrow-bottom {
        .t-btn-oval {
            &__img {
                transform: rotate(-65deg) scale(1); } } }
    &._arrow-right {
        .t-btn-oval {
            &__img {
                transform: rotate(0) scale(1); } }
        .t-btn-arrow {
            transform: rotate(-90deg); } }
    &-oval {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        &__img {
            transition: transform .16s; } }
    &-arrow {
        position: absolute;
        width: 14px;
        height: 9px;
        &__span {
            width: 12px;
            height: 2px;
            background-color: #ffffff;
            border-radius: 20px;
            transform: rotate(45deg);
            transform-origin: 0 0;
            position: relative;
            display: block;
            &:after {
                content: '';
                position: absolute;
                top: -5px;
                left: 5px;
                width: 12px;
                height: 2px;
                background-color: #ffffff;
                border-radius: 20px;
                transform: rotate(90deg); } } }
    @media (min-width: 1024px) {
        &:hover {
            transform: translateX(10px);
            &._arrow-bottom {
                .t-btn-oval {
                    &__img {
                        transform: rotate(-65deg) scale(1.1); } } }
            &._arrow-right {
                .t-btn-oval {
                    &__img {
                        transform: rotate(0) scale(1.1); } } } } } }

.t-text, p:not(.t-text) {
    font-size: 18px;
    color: $text-add;
    a {
        color: $text-head;
        position: relative;
        display: inline-flex;
        align-items: center;
        transition: color .16s;
        &:active {
            color: $text-head-dark !important; }
        @media (min-width: 1024px) {
            &:hover {
                color: $text-head-light; } }
        //@media (max-width: 1024px)
 }        //    cursor: none
    img {
        margin-left: 3px; }
    & > span {
        display: inline-flex;
        align-items: center; } }

a:not([class]) {
    color: $text-head;
    position: relative;
    display: inline-flex;
    align-items: center;
    transition: color .16s;
    &:active {
        color: $text-head-dark !important; }
    @media (min-width: 1024px) {
        &:hover {
            color: $text-head-light; } }
    img {
        margin-left: 3px; } }

.t-list {
    list-style-type: none;
    margin: 24px 0 24px 24px;
    li {
        padding-left: 40px;
        margin-bottom: 24px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 14px;
            width: 16px;
            height: 4px;
            background-color: $text-head; }
        a {
            color: $text-head;
            position: relative;
            //display: inline-flex
            align-items: center;
            transition: color .16s;
            &:active {
                color: $text-head-dark !important; }
            @media (min-width: 1024px) {
                &:hover {
                    color: $text-head-light; } }
            //@media (max-width: 1024px)
 }            //    cursor: none
        img {
            margin-left: 3px; } } }

.t-container {
    padding: 64px 0 80px !important; }

.t-line-top {
    box-shadow: inset 0 1px 0 0 rgba(46, 53, 62, 0.1); }

._hidden {
    height: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
    opacity: 0; }

.t-404 {
    padding: 20px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__gif {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1; }
    &__text {
        width: 346px;
        height: 32px; }
    &__text503 {
        width: 274px;
        height: 32px; } }

@media (max-width: 1365px) {
    .t-work-area-fullRight, .t-work-area-big-fullRight {
        margin: 0; } }

@media (max-width: 1263px) {
    .t-work-area {
        padding: 0 48px; }
    .t-work-area-big {
        padding: 0 128px; }
    .t-work-area-big-fullRight {
        padding: 0 0 0 128px; }
    .t-work-area-fullRight {
        padding: 0 0 0 48px; }

    .t-list {
        margin: 24px 0 24px 16px; } }

@media (max-width: 1023px) {
    .t-work-area-big {
        padding: 0 48px; }
    .t-work-area-big-fullRight {
        padding: 0 0 0 48px; }

    h1 {
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: -1.2px; }
    h2 {
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -0.96px; }

    .t-container {
        padding: 32px 0 56px !important; }

    main {
        padding-top: 164px;
        &._notSubmenu {
            padding-top: 80px; } } }

@media (max-width: 767px) {
    h3 {
        font-size: 24px;
        letter-spacing: -0.6px; }

    .t-work-area {
        padding: 0 32px; }
    .t-work-area-big {
        padding: 0 32px; }
    .t-work-area-big-fullRight {
        padding: 0 0 0 32px; }
    .t-work-area-fullRight {
        padding: 0 0 0 32px; }

    .t-container {
        padding: 16px 0 48px !important; }

    main {
        padding-top: 148px; } }

@media (max-width: 599px) {
    .t-work-area {
        padding: 0 24px; }
    .t-work-area-big {
        padding: 0 24px; }
    .t-work-area-big-fullRight {
        padding: 0 0 0 24px; }
    .t-work-area-fullRight {
        padding: 0 0 0 24px; }

    h1 {
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -0.96px; }
    h2 {
        font-size: 24px;
        line-height: 1.5; }

    .t-btn {
        font-size: 14px; }

    .t-list {
        margin: 24px 0; }

    .t-container {
        padding: 16px 0 32px !important; }

    main {
        padding-top: 132px; }

    .t-404 {
        &__text {
            width: 277px;
            height: 26px; }
        &__text503 {
            width: 220px;
            height: 26px; } } }
