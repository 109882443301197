.t-lottery-wallet {
    margin: 40px auto 80px;
    padding: 0 199px;
    @media (max-width: 1263px) {
        padding: 0 128px;
        display: flex;
        flex-direction: column; }
    @media (max-width: 1023px) {
        padding: 0 48px;
        margin: 24px auto 56px; }
    @media (max-width: 767px) {
        padding: 0 32px;
        margin: 16px auto 48px; }
    @media (max-width: 599px) {
        padding: 0 24px;
        margin: 16px auto 32px; }

    &__title {
        @media (max-width: 1263px) {
            padding: 0;
            margin-top: 24px; }
        @media (max-width: 767px) {
            margin-top: 32px; }
        @media (max-width: 599px) {
            margin-top: 24px; }
        h1 {
            font-size: 56px;
            font-weight: 700;
            line-height: 62px;
            letter-spacing: -1.75px;
            @media (max-width: 767px) {
                font-size: 32px;
                line-height: 40px; } } }

    &__layout.t-sidebar-layout {
        margin-top: 64px;
        @media (max-width: 1263px) {
            display: contents; } }

    &__content {
        position: relative;
        flex: 1 1 auto;
        color: $text-base;
        @media (max-width: 1263px) {
            flex: 0 0 auto;
            width: 100%;
            margin-top: 32px; }
        @media (max-width: 1023px) {
            margin-top: 40px; }
        @media (max-width: 767px) {
            margin-top: 24px; }
        @media (max-width: 599px) {
            margin-top: 16px; }

        h2 {
            margin: 32px 0 12px;
            font-weight: 700;
            font-size: 40px;
            line-height: 52px;
            letter-spacing: -0.96px;
            @media (max-width: 1023px) {
                margin: 24px 0 12px; }
            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 30px;
                margin: 24px 0 8px; }
            @media (max-width: 599px) {
                margin: 16px 0 8px; } }

        h3 {
            margin: 32px 0 12px;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.96px;
            @media (max-width: 1023px) {
                margin: 24px 0 12px; }
            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 28px;
                margin: 24px 0 8px; }
            @media (max-width: 599px) {
                margin: 16px 0 8px; } }

        h4 {
            margin: 32px 0 12px;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.96px;
            @media (max-width: 1023px) {
                margin: 24px 0 12px; }
            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 27px;
                margin: 24px 0 8px; }
            @media (max-width: 599px) {
                margin: 16px 0 8px; } }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin: 12px 0 0;
            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 32px;
                margin: 8px 0 0; } }

        ul, ol {
            list-style: none;
            margin: 12px 0 0;
            display: flex;
            flex-direction: column;
            gap: 8px;
            li {
                position: relative;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                @media (max-width: 767px) {
                    font-size: 18px;
                    line-height: 32px; } } }

        ul {
            padding-left: 16px;
            @media (max-width: 1023px) {
                padding-left: 0; }
            li {
                padding-left: 48px;
                @media (max-width: 1023px) {
                    padding-left: 28px; }
                @media (max-width: 1023px) {
                    padding-left: 24px; }
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    width: 16px;
                    height: 2px;
                    background-color: $text-head;
                    transform: translateY(-50%); } } }

        ol {
            counter-reset: num;
            li {
                padding-left: 60px;
                @media (max-width: 767px) {
                    padding-left: 34px; }
                &::before {
                    content: counter(num) '.';
                    counter-increment: num;
                    position: absolute;
                    color: $text-head;
                    top: 0;
                    left: 10px;
                    width: 32px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    @media (max-width: 767px) {
                        font-size: 18px;
                        line-height: 32px;
                        left: 0;
                        width: 34px; } } } }

        a {
            display: inline-block;
            position: relative;
            padding-right: 17px;
            transition: .2s ease-in-out;
            color: $text-head;
            cursor: pointer;
            line-height: 24px;
            //background-image: url(/img/icons/text_link.svg)
            //background-repeat: no-repeat
            //background-position: center right
 }            //background-size: 13px 13px

        img {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            margin: 16px 0;
            @media (max-width: 1023px) {
                margin: 12px 0; }
            @media (max-width: 767px) {
                margin: 8px 0; } }

        *:first-child {
            margin-top: 0; }

        *:last-child {
            margin-bottom: 0; } } }

.t-sidebar-layout {
    display: flex;
    align-items: flex-start;
    gap: 32px; }

.t-sidebar {
    width: 300px;
    flex: 0 0 300px;
    padding: 0 20px 0 0;
    position: sticky;
    top: 128px;
    transition: all .4s ease-in-out;
    max-height: 58.5vh;
    overflow: auto;
    @media (max-width: 1263px) {
        position: relative;
        overflow: hidden;
        max-height: none;
        flex: 1 1 auto;
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 0;
        padding: 0 16px;
        padding-bottom: 0;
        top: 0;
        order: -1;
        background-color: transparent;
        &.expanded {
            background-color: #E8F4FC;
            margin-bottom: 16px;
            padding-bottom: 16px;
            @media (max-width: 767px) {
                margin-bottom: 0; }
            @media (max-width: 599px) {
                paddibg-bottom: 12px; }
            .t-sidebar__title {
                border-color: $text-head; }
            .t-sidebar__burger {
                .close-icon {
                    opacity: 1;
                    transition: all .4s ease-in-out; }
                .open-icon {
                    opacity: 0;
                    transition: all .4s ease-in-out; } } } }

    &__title {
        display: flex;
        align-items: center;
        gap: 24px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.96px;
        transition: all .2s ease-in-out;
        @media (max-width: 1263px) {
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            padding-top: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #EAEBEC;
            border-color: #EAEBEC;
            cursor: pointer; }
        @media (max-width: 1023px) {
            font-size: 16px;
            line-height: 24px;
            padding-top: 16px;
            padding-bottom: 16px; }
        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 32px; }
        @media (max-width: 599px) {
            padding-top: 12px;
            padding-bottom: 12px; } }

    &__burger {
        display: none;
        position: relative;
        @media (max-width: 1263px) {
            display: block;
            width: 24px;
            height: 24px;
            cursor: pointer; }
        .close-icon {
            opacity: 0;
            transition: all .4s ease-in-out; }
        .open-icon {
            opacity: 1;
            transition: all .4s ease-in-out; }
        svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px; } }

    &__list {
        list-style: none;
        margin-top: 16px;
        @media (max-width: 1263px) {
            display: none; }
        @media (max-width: 599px) {
            margin-top: 24px; }

        &-item {
            position: relative;
            line-height: 30px;
            &:not(:last-child) {
                margin-bottom: 16px;
                @media (max-width: 767px) {
                    margin-bottom: 12px;
                    line-height: 32px; } }
            &.active {
                .t-sidebar__sublist {
                    display: flex; }
                .t-sidebar__sublist-title {
                    color: $text-head; } } } }

    &__sublist {
        display: none;
        flex-direction: column;
        margin-top: 8px;
        gap: 8px;
        @media (max-width: 767px) {
            gap: 4px; }
        &-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.8px;
            transition: all .2s ease-in-out;
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 24px; }
            &:hover {
                color: $text-head; } }
        &-link {
            display: block;
            transition: all .2s ease-in-out;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 32px; }
            &:hover {
                color: $text-head; }
            &.active {
                color: $text-head;
                pointer-events: none; } } } }
